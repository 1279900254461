import { graphql, useStaticQuery } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { HTMLAttributes } from 'react';
import './fonts.css';
import styles from './custom.module.css';
import clsx from 'clsx';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Footer from '@components/Layout/Footer';
import HackCU11Header from '@components/Layout/Header11';
import FrontPageDesign from '@components/FrontPageDesign';
import skierImage from '../../images/skier.svg';

const HackathonCard: React.FC<HTMLAttributes<HTMLDivElement>> = ({
	className,
	children,
	...rest
}) => (
	<div {...rest} className={clsx('rounded-lg shadow-md p-6', className)}>
		{children}
	</div>
);

const IndexPage = (): JSX.Element => {
	const {
		data,
		schedule0,
		schedule1,
		schedule2,
		FAQdata,
		sponsorsData: { nodes: sponsors },
	} = useStaticQuery(graphql`
		{
			data: allHackcu11TracksYaml {
				nodes {
					title
					description
				}
			}

			schedule0: allHackcu11Schedule0Yaml {
				nodes {
					time0
					event0
				}
			}

			schedule1: allHackcu11Schedule1Yaml {
				nodes {
					time
					event
				}
			}

			schedule2: allHackcu11Schedule2Yaml {
				nodes {
					time2
					event2
				}
			}

			FAQdata: allHackcu11FaqYaml {
				nodes {
					question
					answer
				}
			}

			sponsorsData: allHackcu11SponsorsYaml {
				nodes {
					name
					url
					image
				}
			}
		}
	`);

	return (
		<>
			<GatsbySeo title="HackCU 11" description="HackCU 11 - Colorado's Largest Annual Hackathon!" />
			<HackCU11Header />

			{/* Title Section */}
			<div className="bg-hackcu11-background-500 text-hackcu11-gray-500 min-h-screen">
				<section className="text-center" id="home" style={{ marginTop: '80px' }}>
					<FrontPageDesign />
					<h2 className="text-hackcu11-gray-500 text-2xl mt-4 mb-7 tracking-wider sm:text-3xl md:text-4xl">
						March 1st - 2nd, 2025
					</h2>
				</section>

				{/* Main Content */}
				<div className="w-full bg-hackcu11-background-500">
					<div className="mx-auto max-w-7xl px-3 xl:px-0">
						<div className="space-y-8 pt-1">
							{/* Combined About Section */}
							<HackathonCard className="bg-hackcu11-secondary-500 text-white">
								<h1 className="text-center font-bold text-5xl mb-8" id="about">
									About
								</h1>
								<p className="text-left mb-8">
									Join us on March 1st and 2nd, 2025, for our flagship annual hackathon, HackCU 11!
									This is your chance to innovate and create projects across various exciting
									tracks. Whether you&#39;re a seasoned coder or a curious beginner, you&#39;ll find
									workshops and seminars tailored for you. Submit your project to win special prizes
									and gain invaluable experience. HackCU 11 will be held at the Jennie Smoly
									Caruthers Biotechnology Building on CU Boulder East Campus. Space is limited, so
									be sure to register soon!
								</p>

								<div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
									<div>
										<h2 className="text-center font-semibold text-3xl mb-4">Our Story</h2>
										<p className="text-left mb-8">
											HackCU was started in 2014 by a group of passionate students who wanted to
											provide other students the time and space to work on projects they don&#39;t
											normally work on through school. Since then we&#39;ve been committed to
											fulfilling our mission of bringing you high-quality, inclusive events open to
											people of all levels of experience.
										</p>
									</div>
									<div>
										<h2 className="text-center font-semibold text-3xl mb-4">Why You?</h2>
										<p className="text-left mb-8">
											Like to write programs? Dig into interesting datasets? Design creative works?
											Or maybe you&#39;ve never written a line of code? We will provide the space
											and the resources for you to express your creativity, ingenuity, and
											determination to create anything you want to. Whether you&#39;re building your
											first website, dabbling in Photoshop, or hacking with an Oculus Rift, HackCU
											is for you. HackCU is for people of all backgrounds and experiences. So, come
											and join us!
										</p>
									</div>
								</div>
							</HackathonCard>

							{/* Tracks Section */}
							<HackathonCard className="relative bg-white text-hackcu11-secondary-500 mt-16">
								<h1 className="text-center font-bold text-5xl mb-10" id="tracks">
									Tracks
								</h1>
								<div className="relative mt-12 space-y-20">
									{/* Slalom Flags and Cards */}
									{data.nodes.map(({ title, description }, index) => (
										<div
											key={title}
											className="flex items-center justify-between space-x-8 sm:space-x-12"
											style={{
												transform: `translateX(${index % 2 === 0 ? '5%' : '-5%'})`,
												overflowX: 'hidden',
											}}
										>
											{index % 2 !== 0 && (
												<img
													src={skierImage as string}
													alt="Skier"
													className="skier-image skier-left"
													style={{
														width: '20vw',
														maxWidth: '220px',
														height: 'auto',
														transform: 'translateX(20%)',
														marginLeft: '1%',
														marginRight: '1%',
													}}
												/>
											)}
											{index % 2 !== 0 && (
												<div
													className="flex items-center flag-container"
													style={{ width: '5vw', marginLeft: '5%', marginRight: '1%' }}
												>
													<div className="w-1 h-20 bg-gray-700"></div>
													<div
														className={`w-10 h-8 relative ${
															index === 0 || index === 2 ? 'bg-red-700' : 'bg-hackcu11-primary-500'
														}`}
														style={{
															clipPath: 'rectangle(0 0, 100% 100%)',
															top: '-50%',
														}}
													></div>
													<div className="w-1 h-20 bg-gray-700"></div>
												</div>
											)}

											{/* Track Card */}
											<div
												className="p-10 bg-hackcu11-gray-500 shadow-md rounded-md w-full sm:w-4/5 track-card"
												style={{ margin: '0.5% 1%' }}
											>
												<h2 className="font-bold text-3xl text-center mb-3">{title}</h2>
												{description.startsWith('function') ? (
													<MDXRenderer>{description}</MDXRenderer>
												) : (
													<p className="text-xl text-center">{description}</p>
												)}
											</div>

											{index % 2 === 0 && (
												<div
													className="flex items-center flag-container"
													style={{ width: '5vw', marginLeft: '2.2%', marginRight: '3.5%' }}
												>
													<div className="w-1 h-20 bg-gray-700"></div>
													<div
														className={`w-10 h-8 relative ${
															index === 0 || index === 2 ? 'bg-red-700' : 'bg-hackcu11-primary-500'
														}`}
														style={{
															clipPath: 'rectangle(0 0, 100% 100%)',
															top: '-50%',
														}}
													></div>
													<div className="w-1 h-20 bg-gray-700"></div>
												</div>
											)}
											{index % 2 === 0 && (
												<img
													src={skierImage as string}
													alt="Skier"
													className="skier-image skier-right"
													style={{
														width: '20vw',
														maxWidth: '220px',
														height: 'auto',
														transform: 'scaleX(-1) translateX(20%)',
														marginLeft: '1%',
														marginRight: '1%',
													}}
												/>
											)}
										</div>
									))}
								</div>
							</HackathonCard>

							{/* FAQs Section */}
							<HackathonCard className={clsx(styles.card, 'bg-hackcu11-secondary-500 text-white')}>
								<h1 className="text-center font-bold text-5xl mb-8" id="faq">
									FAQs
								</h1>
								<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
									{FAQdata.nodes.map(({ question, answer }) => (
										<div key={question} className="border border-white p-8 rounded-lg shadow-xl">
											<h2 className="font-semibold text-2xl mb-4 flex justify-center items-center text-center">
												<span className="text-sm mr-2">❄️</span> {question}{' '}
												<span className="text-sm ml-2">❄️</span>
											</h2>
											<p className="text-lg leading-relaxed text-left">{answer}</p>
										</div>
									))}
								</div>
							</HackathonCard>

							{/* Schedule Section */}
							<HackathonCard className={clsx(styles.card, 'bg-white text-hackcu11-secondary-500')}>
								<h1 className="text-center font-bold text-5xl" id="schedule">
									Schedule
								</h1>
								<div className="flex flex-col justify-center items-center mt-8">
									{schedule0 && schedule0.nodes && schedule0.nodes.length > 0 && (
										<div className="bg-hackcu11-gray-500 p-6 rounded-lg shadow-md w-full max-w-3xl mx-3 mb-8">
											<h1 className="font-bold text-3xl text-center mb-3">
												Day 0 - Friday, February 28th
											</h1>
											<table className="w-full border-collapse text-center">
												<thead className="text-md">
													<tr>
														<th>Time</th>
														<th>Event</th>
													</tr>
												</thead>
												<tbody>
													{schedule0.nodes.map(({ time0, event0 }, index) => (
														<tr
															key={event0 || `event-${index}`}
															style={{
																borderTop: index === 0 ? '2px solid black' : '1px solid black',
															}}
														>
															<td className="p-1">{time0}</td>
															<td className="p-1">
																{event0.startsWith('function') ? (
																	<MDXRenderer>{event0}</MDXRenderer>
																) : (
																	<p>{event0}</p>
																)}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									)}
									{schedule1 && schedule1.nodes && schedule1.nodes.length > 0 && (
										<div className="bg-hackcu11-gray-500 p-6 rounded-lg shadow-md w-full max-w-3xl mx-3 mb-8">
											<h1 className="font-bold text-3xl text-center mb-3">
												Day 1 - Saturday, March 1st
											</h1>
											<table className="w-full border-collapse text-center">
												<thead className="text-md">
													<tr>
														<th>Time</th>
														<th>Event</th>
													</tr>
												</thead>
												<tbody>
													{schedule1.nodes.map(({ time, event }, index) => (
														<tr
															key={event || `event-${index}`}
															style={{
																borderTop: index === 0 ? '2px solid black' : '1px solid black',
															}}
														>
															<td className="p-1">{time}</td>
															<td className="p-1">
																{event.startsWith('function') ? (
																	<MDXRenderer>{event}</MDXRenderer>
																) : (
																	<p>{event}</p>
																)}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									)}
									{schedule2 && schedule2.nodes && schedule2.nodes.length > 0 && (
										<div className="bg-hackcu11-gray-500 p-6 rounded-lg shadow-md w-full max-w-3xl mx-3">
											<h1 className="font-bold text-3xl text-center mb-3">
												Day 2 - Sunday, March 2nd
											</h1>
											<table className="w-full border-collapse text-center">
												<thead className="text-md">
													<tr>
														<th>Time</th>
														<th>Event</th>
													</tr>
												</thead>
												<tbody>
													{schedule2.nodes.map(({ time2, event2 }, index) => (
														<tr
															key={event2 || `event-${index}`}
															style={{
																borderTop: index === 0 ? '2px solid black' : '1px solid black',
															}}
														>
															<td className="p-1">{time2}</td>
															<td className="p-1">
																{event2.startsWith('function') ? (
																	<MDXRenderer>{event2}</MDXRenderer>
																) : (
																	<p>{event2}</p>
																)}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									)}
								</div>
							</HackathonCard>

							{/* Hacker Info Section */}
							<HackathonCard className="bg-hackcu11-secondary-500 text-white">
								<h1 className="text-center font-bold text-5xl mb-8" id="hacker-info">
									Hacker Info
								</h1>
								<div className="flex flex-col items-center gap-6">
									<a
										href="https://docs.google.com/document/d/1PMqyqBOMKI6Psph5XGyH3g8oMUSN3lrGJLVO8XRzltg/edit?tab=t.0"
										target="_blank"
										rel="noopener noreferrer"
										className="bg-white text-hackcu11-secondary-500 font-bold py-4 px-8 text-xl rounded-full shadow-lg hover:bg-gray-200 transition-all duration-300"
									>
										❄️ Hacker Guide ❄️
									</a>
									<a
										href="https://hackcu-11.devpost.com/"
										target="_blank"
										rel="noopener noreferrer"
										className="bg-white text-hackcu11-secondary-500 font-bold py-4 px-8 text-xl rounded-full shadow-lg hover:bg-gray-200 transition-all duration-300"
									>
										❄️ Devpost Project Submission ❄️
									</a>
								</div>
							</HackathonCard>

							{/* Sponsors Section */}
							<HackathonCard className={clsx(styles.card, 'bg-white text-hackcu11-secondary-500')}>
								<h1 className="text-center font-bold text-5xl" id="sponsors">
									Sponsors
								</h1>
								<div className="grid grid-cols-2 gap-8 justify-items-center">
									{sponsors.map(({ name, image, url }) => (
										<div key={name} className="flex justify-center items-center p-4">
											<a href={url} target="_blank" rel="noopener noreferrer">
												<img
													src={require(`../../../content/sponsors${image.substring(1)}`)}
													alt={`${name} logo`}
													className="w-3/4 h-auto object-contain"
												/>
											</a>
										</div>
									))}
								</div>
							</HackathonCard>

							{/* Footer */}
							<Footer />
						</div>
					</div>
				</div>

				{/* </div> */}
			</div>
		</>
	);
};

export default IndexPage;
